import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import Panel from "../../common/components/panel";
import { StatusComponent } from "../../common/components/status";
import Timestamp from "../../common/components/timestamp";
import {
  LeafletDraftFragment,
  LeafletDraftState,
} from "../../graphql/generated";
import { DescriptionList } from "../../common/components/descriptionlist";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DescriptionListItem from "../../common/components/descriptionlistitem";

const LeafletDraftSummary: React.FC<{
  leafletDraft?: LeafletDraftFragment;
}> = ({ leafletDraft }) => {
  return (
    <>
      <Panel>
        <Panel.Body summary>
          <DescriptionList>
            <DescriptionListContainer title="Status">
              <StatusComponent entity={leafletDraft} />

              {leafletDraft?.state === LeafletDraftState.Deleted && (
                <>
                  {" "}
                  at{" "}
                  <Timestamp
                    timestamp={leafletDraft?.deletedAt}
                    format="long"
                    popover
                  />
                </>
              )}
            </DescriptionListContainer>
            <DescriptionListContainer title="Created">
              <Timestamp
                timestamp={leafletDraft?.createdAt}
                format="long"
                popover
              />
            </DescriptionListContainer>
            <DescriptionListItem
              title="Created by"
              value={leafletDraft?.createdBy.name}
              link={`/accounts/${leafletDraft?.createdBy.id}`}
            />
            <DescriptionListContainer title="Modified">
              <Timestamp
                timestamp={leafletDraft?.modifiedAt}
                format="long"
                popover
              />
            </DescriptionListContainer>
            <DescriptionListItem
              title="Address"
              span={4}
              value={leafletDraft?.address}
            />
            <DescriptionListItem
              title="Environment ID"
              info="Where this draft will publish to"
              value={leafletDraft?.environmentId}
            />
            <DescriptionListItem
              title="Remote ID"
              info="The ID of the listing in the remote system"
              value={leafletDraft?.remoteId ?? "—"}
            />
            {leafletDraft?.listing ? (
              <DescriptionListItem
                title="Listing"
                info="The listing in our system, if it has already been ingested"
                value={leafletDraft?.listing?.id}
                link={`/listings/${leafletDraft?.listing?.id}`}
              />
            ) : (
              <DescriptionListItem
                title="Listing"
                info="The listing in our system, if it has already been ingested"
                value="—"
              />
            )}
            <DescriptionListContainer span={4} title="Errors">
              {leafletDraft?.publishError ? (
                <ul>
                  {leafletDraft.publishError.generic && (
                    <li>{leafletDraft.publishError.generic}</li>
                  )}
                  {leafletDraft.publishError.validationErrors.map((err) => (
                    <li>
                      {err.field && (
                        <>
                          <strong>{err.field}</strong>:{" "}
                        </>
                      )}
                      {err.message}
                    </li>
                  ))}
                </ul>
              ) : (
                <>{"—"}</>
              )}
            </DescriptionListContainer>
          </DescriptionList>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Title>Data</Panel.Title>
        <Panel.Body fullWidth>
          <SyntaxHighlighter language="json" style={a11yDark} showLineNumbers>
            {JSON.stringify(leafletDraft?.data, null, 2)}
          </SyntaxHighlighter>
        </Panel.Body>
      </Panel>
    </>
  );
};

export default LeafletDraftSummary;
