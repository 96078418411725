import React from "react";
import Panel from "../../common/components/panel";
import { useListingInputStatsQuery } from "../../graphql/generated";
import { Status, StatusComponent } from "../../common/components/status";
import LoadingIcon from "../../common/components/loadingicon";
import Error from "../../common/components/error";

const ListingInputStats: React.FC<{ environmentId?: string }> = ({
  environmentId,
}) => {
  const [{ data, fetching, error }] = useListingInputStatsQuery({
    variables: {
      id: environmentId ?? "",
    },
    pause: !environmentId,
  });

  return (
    <Panel>
      <Panel.Title>Draft leaflets</Panel.Title>
      <Panel.Body>
        {fetching && <LoadingIcon />}
        {error && <Error error={error} />}
        {data?.listingInputStats && (
          <table>
            <thead>
              <tr>
                <th className="text-left p-1">State</th>
                <th className="text-right p-1 pl-4">Count</th>
              </tr>
            </thead>
            <tbody>
              {data.listingInputStats.states.map((state) => (
                <tr key={state.id}>
                  <td className="p-1">
                    <StatusComponent
                      status={Status.forLeafletDraftState(state.state)}
                    />
                  </td>
                  <td className="text-right p-1 pl-4">
                    {state.count.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Panel.Body>
    </Panel>
  );
};

export default ListingInputStats;
