import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useMlsQuery } from "../../graphql/generated";
import { ListingInputEdit } from "../components/listing-input-edit";
import MlsTitle from "../components/title";
import { useTitle } from "../../common/utils/title";

const ListingInputEditShow: React.FC = () => {
  const params = useParams();
  const [{ data, error }] = useMlsQuery({
    variables: { id: params.mlsId ?? "" },
    pause: !params.mlsId,
  });
  useTitle("Listing Input", data?.mls.shortName ?? params.mlsId, "MLSes");

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        {data && (
          <div className="max-w-7xl mx-auto py-4">
            <MlsTitle mls={data?.mls} />
            <ListingInputEdit id={data?.mls.id} />
          </div>
        )}
      </Transition>
    </>
  );
};

export default ListingInputEditShow;
