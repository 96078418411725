import React from "react";
import { useParams } from "react-router-dom";
import { useTitle } from "../../common/utils/title";
import ListingInputStats from "../components/stats";
import PageHeader from "../../common/components/page-header";
import ListingInputPublishErrors from "../components/publish_errors";
import ListingInputRecentDrafts from "../components/recent-drafts";

const ListingInputShow: React.FC = () => {
  const params = useParams();
  const id = params.environmentId;

  useTitle(id, "Listing Input");

  return (
    <div className="max-w-7xl mx-auto py-4">
      <PageHeader>
        <PageHeader.Title>{id} Listing Input</PageHeader.Title>
      </PageHeader>
      <ListingInputStats environmentId={id} />
      <ListingInputRecentDrafts environmentId={id} />
      <ListingInputPublishErrors environmentId={id} />
    </div>
  );
};

export default ListingInputShow;
