import { Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import { useLeafletDraftQuery } from "../../graphql/generated";
import { useTitle } from "../../common/utils/title";
import LeafletDraftTitle from "../components/title";
import LeafletDraftSummary from "../components/summary";
import LeafletDraftMedia from "../components/media";

const LeafletDraftShow: React.FC = () => {
  const params = useParams();

  const [{ data, error }] = useLeafletDraftQuery({
    variables: { id: params.leafletDraftId ?? "" },
    pause: !params.leafletDraftId,
  });

  useTitle(
    data?.leafletDraft?.address ??
      data?.leafletDraft?.id ??
      params.leafletDraftId,
    "Leaflet Drafts"
  );

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto py-4">
          <LeafletDraftTitle leafletDraft={data?.leafletDraft} />
          <LeafletDraftSummary leafletDraft={data?.leafletDraft} />
          <LeafletDraftMedia leafletDraft={data?.leafletDraft} />
        </div>
      </Transition>
    </>
  );
};

export default LeafletDraftShow;
