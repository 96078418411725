import React from "react";
import { useTitle } from "../../common/utils/title";
import Panel from "../../common/components/panel";
import { Link } from "react-router-dom";

const ListingInputList: React.FC = () => {
  useTitle("Listing Input");

  return (
    <Panel>
      <Panel.Title>Listing Input</Panel.Title>
      <Panel.Body>
        <ul className="list-disc list-inside">
          <li>
            <Link to="/listing_input/zen">Zen (Office exclusives)</Link>
          </li>
          <li>
            <Link to="/listing_input/sfar">SFAR</Link>
          </li>
          <li>
            <Link to="/listing_input/sfar.staging">SFAR staging</Link>
          </li>
        </ul>
      </Panel.Body>
    </Panel>
  );
};

export default ListingInputList;
