import { CalendarIcon } from "@heroicons/react/24/outline";
import React from "react";
import Timestamp from "../../common/components/timestamp";
import { LeafletDraftFragment } from "../../graphql/generated";
import PageHeader from "../../common/components/page-header";
import LeafletDraftPic from "./pic";

const LeafletDraftTitle: React.FC<{ leafletDraft?: LeafletDraftFragment }> = ({
  leafletDraft,
}) => {
  return (
    <PageHeader>
      <PageHeader.Pic>
        <LeafletDraftPic leafletDraft={leafletDraft} large />
      </PageHeader.Pic>
      <PageHeader.Title>
        {leafletDraft?.address ?? leafletDraft?.id}
      </PageHeader.Title>
      <PageHeader.Details>
        <PageHeader.DetailsItem icon={CalendarIcon}>
          Created:{" "}
          <Timestamp
            timestamp={leafletDraft?.createdAt}
            format="short"
            popover
          />
        </PageHeader.DetailsItem>
      </PageHeader.Details>
    </PageHeader>
  );
};

export default LeafletDraftTitle;
