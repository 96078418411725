import React from "react";
import Panel from "../../common/components/panel";
import { LeafletDraftWithMediaFragment } from "../../graphql/generated";
import { DescriptionList } from "../../common/components/descriptionlist";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import CommonSwitch from "../../common/components/switch";

const LeafletDraftMedia: React.FC<{
  leafletDraft?: LeafletDraftWithMediaFragment;
}> = ({ leafletDraft }) => {
  return (
    <>
      <Panel>
        <Panel.Title>
          Media{" "}
          {leafletDraft && (
            <span className="text-xs text-gray-500">
              {leafletDraft?.mediaItems?.length}
            </span>
          )}
        </Panel.Title>
        {leafletDraft?.mediaItems.length ? (
          <>
            {leafletDraft.mediaItems.map((item) => (
              <MediaBody key={item.id} mediaItem={item} />
            ))}
          </>
        ) : (
          <Panel.Body>No media</Panel.Body>
        )}
      </Panel>
    </>
  );
};

const MediaBody: React.FC<{
  mediaItem: LeafletDraftWithMediaFragment["mediaItems"][0];
}> = ({ mediaItem }) => {
  const canView = !!mediaItem.url;
  const [imageVisible, setImageVisible] = React.useState(false);

  return (
    <Panel.Body>
      <DescriptionList>
        <DescriptionListItem
          title="Short description"
          value={mediaItem.shortDescription ?? "—"}
        />
        <DescriptionListItem
          title="Long description"
          value={mediaItem.longDescription ?? "—"}
        />
        <DescriptionListItem
          title="Media status"
          value={mediaItem.mediaState ?? "—"}
        />
        {canView && (
          <DescriptionListContainer title="Show media">
            <CommonSwitch
              enabled={imageVisible}
              toggle={() => setImageVisible(!imageVisible)}
              label="Show media"
            />
          </DescriptionListContainer>
        )}
        {imageVisible && (
          <DescriptionListContainer title="Media" span={4}>
            <div>{mediaItem.url}</div>
            <div>
              <img src={mediaItem.url ?? undefined} />
            </div>
          </DescriptionListContainer>
        )}
      </DescriptionList>
    </Panel.Body>
  );
};

export default LeafletDraftMedia;
