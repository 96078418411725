import { AuthConfig, authExchange } from "@urql/exchange-auth";
import {
  CombinedError,
  createClient,
  errorExchange,
  fetchExchange,
  gql,
} from "urql";
import { cacheExchange } from "@urql/exchange-graphcache";
import { relayPagination } from "@urql/exchange-graphcache/extras";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import schema from "../../graphql/schema.json";
import jwt_decode from "jwt-decode";

const RefreshMutation = gql<
  { refreshAuthentication: { accessToken: string } },
  { token: string }
>`
  mutation RefreshAuthentication($token: String!) {
    refreshAuthentication(refreshToken: $token) {
      __typename
      accessToken
      expiresIn
    }
  }
`;

// const initAuthState = async () => {
//   const token = localStorage.getItem("token");
//   const refreshToken = localStorage.getItem("refreshToken");
//   return { token, refreshToken };
// };

const applicationClient = () => {
  return createClient({
    url: process.env.REACT_APP_API_URL!,
    exchanges: [
      cacheExchange({
        schema,
        resolvers: {
          Query: {
            accounts: relayPagination(),
            agents: relayPagination(),
            lenders: relayPagination(),
            groups: relayPagination(),
            identities: relayPagination(),
            offices: relayPagination(),
            conversations: relayPagination(),
            participants: relayPagination(),
            messages: relayPagination(),
            groupNotes: relayPagination(),
            mails: relayPagination(),
            referrals: relayPagination(),
            tours: relayPagination(),
            accountPatches: relayPagination(),
            agentPatches: relayPagination(),
            groupSettingPatches: relayPagination(),
            groupPatches: relayPagination(),
            listingPatches: relayPagination(),
            officePatches: relayPagination(),
            savedFilterPatches: relayPagination(),
            tourPatches: relayPagination(),
            leafletDrafts: relayPagination(),
            rmProblemsForResourceType: relayPagination(),
            rmProblemsForResource: relayPagination(),
            rmProblemsForMapping: relayPagination(),
          },
          CanbyAnalysis: {
            fieldsConnection: relayPagination(),
          },
        },
        keys: {
          AgentLicense: () => null,
          BilledExternalToStripe: () => null,
          CanbyBooleanFieldStatistics: () => null,
          CanbyDateFieldStatistics: () => null,
          CanbyFieldKeyword: () => null,
          CanbyFieldStatistics: () => null,
          CanbyKeywordFieldStatistics: () => null,
          CanbyListFieldStatistics: () => null,
          CanbyNumberFieldStatistics: () => null,
          CanbyObjectFieldStatistics: () => null,
          CanbyRandomSampleIngestType: () => null,
          EnterpriseBillingStripeSubscription: () => null,
          EventHistogram: () => null,
          EventHistogramSummary: () => null,
          Histogram: () => null,
          HistogramSummary: () => null,
          IndividualBilling: () => null,
          ListingAddress: () => null,
          ListingInputDefinitionField: () => null,
          ListingInputDefinitionOrder: () => null,
          ListingInputDefinitionOrderItemField: () => null,
          ListingViewCounts: () => null,
          OfficeAddress: () => null,
          ResourceMetadataExtra: () => null,
          RMJsonPointerMappingSource: () => null,
          SessionCounts: () => null,
          ShowingTimeConfig: () => null,
        },
      }),
      errorExchange({
        onError: (error: CombinedError) => {
          console.error(error);
        },
      }),
      authExchange(async (utils) => {
        const suppressAuth = "_zenSuppressAuth";
        const authConfig: AuthConfig = {
          addAuthToOperation: (operation) => {
            // When performing a refreshAuthentication, don't send our
            // existing token.
            if (operation.context[suppressAuth] ?? false) {
              return operation;
            }
            const token = localStorage.getItem("token");
            if (!token) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`,
            });
          },
          didAuthError: (error) => {
            return error.graphQLErrors.some(
              (e) =>
                e.extensions?.type === "TokenExpired" ||
                e.extensions?.type === "InvalidToken" ||
                e.extensions?.type === "Denied"
            );
          },
          refreshAuth: async () => {
            const refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken) {
              const result = await utils.mutate(
                RefreshMutation,
                {
                  token: refreshToken as string,
                },
                {
                  // When performing a refreshAuthentication, don't send our
                  // existing token.
                  [suppressAuth]: true,
                }
              );

              if (result.data?.refreshAuthentication) {
                const token = result.data.refreshAuthentication.accessToken;
                localStorage.setItem("token", token);
              } else {
                // This is where auth has gone wrong and we need to clean up and redirect to a login page
                localStorage.clear();
              }
            } else {
              localStorage.clear();
            }
          },
          willAuthError() {
            const token = localStorage.getItem("token");
            if (token) {
              const { exp } = jwt_decode<{ exp: number }>(token);
              if (exp < Math.round(Date.now() / 1000)) {
                return true;
              }
            } else {
              return true;
            }

            return false;
          },
        };

        return authConfig;
      }),
      requestPolicyExchange({}),
      fetchExchange,
    ],
  });
};

export default applicationClient;
