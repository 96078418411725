import React from "react";
import { useClient } from "urql";
import {
  LeafletDraftsDocument,
  LeafletDraftState,
  useLeafletDraftsQuery,
} from "../../graphql/generated";
import { classNames } from "../../common/utils/classnames";
import CommonSwitch from "../../common/components/switch";
import { Transition } from "@headlessui/react";
import Loading from "../../common/components/loading";
import Error from "../../common/components/error";
import { useTitle } from "../../common/utils/title";
import LeafletDraftCard from "../components/card";
import Panel from "../../common/components/panel";

enum PseudoState {
  Deleted,
  Draft,
  Publishing,
  PublishingError,
  Waiting,
  Published,
}

function pseudoStateName(pseudoState: PseudoState): string {
  switch (pseudoState) {
    case PseudoState.Deleted:
      return "Deleted";
    case PseudoState.Draft:
      return "Draft";
    case PseudoState.Publishing:
      return "Publishing";
    case PseudoState.PublishingError:
      return "Error";
    case PseudoState.Waiting:
      return "Waiting for Listing";
    case PseudoState.Published:
      return "Published";
  }
}

function pseudoStateToStates(pseudoState: PseudoState): LeafletDraftState[] {
  switch (pseudoState) {
    case PseudoState.Deleted:
      return [LeafletDraftState.Deleted];
    case PseudoState.Draft:
      return [LeafletDraftState.Draft];
    case PseudoState.Publishing:
      return [
        LeafletDraftState.ReadyForPublish,
        LeafletDraftState.PublishingListing,
        LeafletDraftState.PublishingMedia,
      ];
    case PseudoState.PublishingError:
      return [
        LeafletDraftState.PublishListingError,
        LeafletDraftState.PublishMediaError,
      ];
    case PseudoState.Waiting:
      return [LeafletDraftState.WaitingForListing];
    case PseudoState.Published:
      return [LeafletDraftState.Published];
  }
}

function pseudoStatesToStates(
  pseudoStates: Set<PseudoState>
): LeafletDraftState[] {
  let states: LeafletDraftState[] = [];
  for (const pseudoState of Array.from(pseudoStates)) {
    const additionalStates = pseudoStateToStates(pseudoState);
    states = [...states, ...additionalStates];
  }
  return states;
}

const LeafletDraftList: React.FC = () => {
  useTitle("Leaflet Drafts");
  const graphqlClient = useClient();
  const [loading, setLoading] = React.useState(false);

  const knownPseudoStates = [
    PseudoState.Draft,
    PseudoState.Publishing,
    PseudoState.Waiting,
    PseudoState.Published,
    PseudoState.PublishingError,
    PseudoState.Deleted,
  ];

  const knownEnvironmentIds = ["zen", "sfar", "sfar.staging"];

  const [pseudoStates, setPseudoStates] = React.useState(
    () => new Set(knownPseudoStates)
  );
  const [environmentIds, setEnvironmentIds] = React.useState(
    () => new Set(knownEnvironmentIds)
  );

  const togglePseudoState = React.useCallback(
    (pseudoState: PseudoState) => {
      const newPseudoStates = new Set(pseudoStates);
      if (newPseudoStates.has(pseudoState)) {
        newPseudoStates.delete(pseudoState);
      } else {
        newPseudoStates.add(pseudoState);
      }
      setPseudoStates(newPseudoStates);
    },
    [pseudoStates]
  );

  const toggleEnvironmentId = React.useCallback(
    (environmentId: string) => {
      const newEnvironmentIds = new Set(environmentIds);
      if (newEnvironmentIds.has(environmentId)) {
        newEnvironmentIds.delete(environmentId);
      } else {
        newEnvironmentIds.add(environmentId);
      }
      setEnvironmentIds(newEnvironmentIds);
    },
    [environmentIds]
  );

  const [{ data, error, fetching }] = useLeafletDraftsQuery({
    variables: {
      first: 20,
      states: pseudoStatesToStates(pseudoStates),
      environmentIds: Array.from(environmentIds),
    },
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.leafletDrafts.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(LeafletDraftsDocument, {
          first: 20,
          after: data.leafletDrafts.pageInfo.endCursor,
          states: pseudoStatesToStates(pseudoStates),
          environmentIds: Array.from(environmentIds),
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, pseudoStates, environmentIds]);

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                Leaflet Drafts
                <span className="text-sm text-gray-500 px-2">
                  {fetching
                    ? "Loading..."
                    : `Total: ${data?.leafletDrafts.total}`}
                </span>
              </h2>
              <div className="mt-1 grid grid-cols-1 gap-0">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  {knownEnvironmentIds.map((environmentId) => (
                    <CommonSwitch
                      key={environmentId}
                      enabled={environmentIds.has(environmentId)}
                      toggle={() => toggleEnvironmentId(environmentId)}
                      label={environmentId}
                    />
                  ))}
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  {knownPseudoStates.map((pseudoState) => {
                    const name = pseudoStateName(pseudoState);
                    return (
                      <CommonSwitch
                        key={pseudoState}
                        enabled={pseudoStates.has(pseudoState)}
                        toggle={() => togglePseudoState(pseudoState)}
                        label={name}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <Panel>
            <Panel.Body>
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                {data?.leafletDrafts?.edges?.map((leafletDraft) => (
                  <LeafletDraftCard
                    leafletDraft={leafletDraft?.node}
                    key={leafletDraft?.node.id}
                  />
                ))}
              </div>
            </Panel.Body>
            {data &&
              data?.leafletDrafts.total >
                (data?.leafletDrafts?.edges?.length ?? 0) && (
                <Panel.Footer>
                  <button
                    className={classNames(
                      loading
                        ? "bg-gray-500 text-white"
                        : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                      "font-bold py-2 px-4 rounded"
                    )}
                    onClick={() => fetchMore()}
                    disabled={loading}
                  >
                    Fetch More
                  </button>
                </Panel.Footer>
              )}
          </Panel>
        </div>
      </Transition>
    </>
  );
};

export default LeafletDraftList;
