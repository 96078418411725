import React from "react";
import Panel from "../../common/components/panel";
import LoadingIcon from "../../common/components/loadingicon";
import Error from "../../common/components/error";
import Timestamp from "../../common/components/timestamp";
import { useLeafletPublishErrorLogItemsQuery } from "../../graphql/generated";

const ListingInputPublishErrors: React.FC<{ environmentId?: string }> = ({
  environmentId,
}) => {
  const [{ data, fetching, error }] = useLeafletPublishErrorLogItemsQuery({
    variables: {
      id: environmentId ?? "",
    },
    pause: !environmentId,
  });

  return (
    <Panel>
      <Panel.Title>Publish errors</Panel.Title>
      <Panel.Body>
        {fetching && <LoadingIcon />}
        {error && <Error error={error} />}
        {data && (
          <table>
            <thead>
              <tr>
                <th className="text-sm text-left p-1">Field</th>
                <th className="text-sm text-left p-1">Message</th>
                <th className="text-sm text-right p-1">Count</th>
                <th className="text-sm text-left p-1">First seen</th>
                <th className="text-sm text-left p-1">Last seen</th>
              </tr>
            </thead>
            <tbody>
              {data.leafletPublishErrorLogItems.map((item) => (
                <tr key={item.id}>
                  <td className="p-1 text-sm">{item.field ?? "—"}</td>
                  <td className="p-1 text-sm">{item.message}</td>
                  <td className="p-1 text-sm text-right">
                    {item.count.toLocaleString()}
                  </td>

                  <td className="p-1 text-sm">
                    <Timestamp
                      timestamp={item.firstSeenAt}
                      format="short"
                      popover
                    />
                  </td>
                  <td className="p-1 text-sm">
                    <Timestamp
                      timestamp={item.lastSeenAt}
                      format="short"
                      popover
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Panel.Body>
    </Panel>
  );
};

export default ListingInputPublishErrors;
