import React from "react";
import { Link } from "react-router-dom";
import { longDateFormat } from "../../common/utils/dateformat";
import { LeafletDraftFragment } from "../../graphql/generated";
import LeafletDraftPic from "./pic";

const LeafletDraftCard: React.FC<{ leafletDraft?: LeafletDraftFragment }> = ({
  leafletDraft,
}) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      <div className="flex-shrink-0">
        <LeafletDraftPic leafletDraft={leafletDraft} />
      </div>
      <div className="flex-1 min-w-0">
        <Link
          to={`/leaflet_drafts/${leafletDraft?.id}`}
          className="focus:outline-none"
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">
            {leafletDraft?.environmentId} &bull; {leafletDraft?.createdBy?.name}
          </p>
          {leafletDraft?.address ? (
            <p className="text-sm font-medium text-gray-900">
              {leafletDraft.address}
            </p>
          ) : (
            <p className="text-sm font-medium text-gray-300">no address</p>
          )}

          <p className="text-xs text-gray-500 truncate"></p>
          <p className="text-xs text-gray-500 truncate">
            Created at {longDateFormat(leafletDraft?.createdAt)}
          </p>
          <p className="text-xs text-gray-500 truncate">
            Modified at {longDateFormat(leafletDraft?.modifiedAt)}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default LeafletDraftCard;
