import React from "react";
import Panel from "../../common/components/panel";
import {
  LeafletDraftsDocument,
  useLeafletDraftsQuery,
} from "../../graphql/generated";
import LoadingIcon from "../../common/components/loadingicon";
import Error from "../../common/components/error";
import LeafletDraftCard from "../../leaflet-draft/components/card";
import { classNames } from "../../common/utils/classnames";
import { useClient } from "urql";

const ListingInputRecentDrafts: React.FC<{ environmentId?: string }> = ({
  environmentId,
}) => {
  const graphqlClient = useClient();
  const [loading, setLoading] = React.useState(false);

  const [{ data, fetching, error }] = useLeafletDraftsQuery({
    variables: {
      environmentIds: [environmentId ?? ""],
      first: 10,
    },
    pause: !environmentId,
  });

  const fetchMore = React.useCallback(async () => {
    if (data?.leafletDrafts.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(LeafletDraftsDocument, {
          first: 10,
          after: data.leafletDrafts.pageInfo.endCursor,
          environmentIds: [environmentId ?? ""],
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, environmentId]);

  return (
    <Panel>
      <Panel.Title>
        Recent draft leaflets{" "}
        <span className="text-sm text-gray-500 px-2">
          {fetching ? "Loading..." : `Total: ${data?.leafletDrafts.total}`}
        </span>
      </Panel.Title>
      <Panel.Body>
        {fetching && <LoadingIcon />}
        {error && <Error error={error} />}
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.leafletDrafts?.edges?.map((leafletDraft) => (
            <LeafletDraftCard
              leafletDraft={leafletDraft?.node}
              key={leafletDraft?.node.id}
            />
          ))}
        </div>
      </Panel.Body>
      {data &&
        data?.leafletDrafts.total >
          (data?.leafletDrafts?.edges?.length ?? 0) && (
          <Panel.Footer>
            <button
              className={classNames(
                loading
                  ? "bg-gray-500 text-white"
                  : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                "font-bold py-2 px-4 rounded"
              )}
              onClick={() => fetchMore()}
              disabled={loading}
            >
              Fetch More
            </button>
          </Panel.Footer>
        )}
    </Panel>
  );
};

export default ListingInputRecentDrafts;
